import axios from "axios";
import axiosClient from "./axiosClient";

const chatApi = {
    newMessage(data) {
        const url = '/api/new-message';
        return axiosClient.post(url, data);
    },
    getMessages(userId) {
        const url = `/api/get-messages/${userId}`;
        return axiosClient.get(url);
    },
    getAllUsersChat() {
        const url = "/api/get-all-users-chat";
        return axiosClient.get(url);
    },
    getAllUnreadMessages() {
        const url = "/api/get-all-unread-messages";
        return axiosClient.get(url);
    },
    setStatusMessages(data) {
        const url = '/api/set-status-messages';
        return axiosClient.post(url, data);
    },
}
export default chatApi;