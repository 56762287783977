import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Grid, TextField, Tooltip } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import chatApi from "../../Api/chatApi";
import Pusher from "pusher-js";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import userApi from "../../Api/userApi";
import { baseURL } from "../../Constants/env";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import "../../style/Chat.css";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs";
import { viewedMessage } from "../../Slide/MessageSlide";
import { unwrapResult } from "@reduxjs/toolkit";
Chat.propTypes = {
  listChat: [],
};

function Chat(props) {
  const loggedInuser = useSelector((state) => state?.user?.current);
  const isLoggedIn = !!loggedInuser?.id;
  const [messages, setMessages] = useState([]);
  const [userChat, setUserChat] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const { userId } = useParams();
  const userIdNumber = parseInt(userId);
  const { listChat, setListChat } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId !== "new") {
      const fetchMessages = async () => {
        try {
          const dataRes = await chatApi.getMessages(userId);
          setMessages(dataRes.messages);
        } catch (error) {
          console.log(error);
        }
      };
      const fetchUser = async () => {
        try {
          const dataRes = await userApi.getPublicProfice(userId);
          setUserChat(dataRes);
        } catch (error) {
          console.log(error);
        }
      };
      fetchUser();
      fetchMessages();
    }
  }, [userId]);

  const schema = yup.object({
    message: yup
      .string()
      .required("Nhập tin nhắn")
      .max(1000, "Vui lòng nhập ít hơn 1000 kí tự"),
  });
  const {
    register: message,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const newMessage = async (data) => {
    try {
      const result = await chatApi.newMessage(data);
      setMessages((prevMessages) => [...prevMessages, result]);
      reset();
      const userSenderId = parseInt(user?.id);
      const userReceiverId = parseInt(userId);
      const message = data?.message;
      let foundItem = null; // Biến tạm để lưu mục cần di chuyển
      let foundIndex = -1; // Biến tạm để lưu vị trí của mục cần di chuyển
      for (let index = 0; index < listChat.length; index++) {
        const item = listChat[index];
        if (
          item.sender_id == userSenderId &&
          item.receiver_id == userReceiverId
        ) {
          item.content = message;
          item.sender_id = userSenderId;
          item.receiver_id = userReceiverId;
          foundItem = item; // Lưu mục cần di chuyển
          foundIndex = index; // Lưu vị trí của mục cần di chuyển
          break; // Thoát khỏi vòng lặp sau khi tìm thấy phần tử
        } else if (
          item.sender_id == userReceiverId &&
          item.receiver_id == userSenderId
        ) {
          item.content = message;
          item.sender_id = userSenderId;
          item.receiver_id = userReceiverId;
          foundItem = item; // Lưu mục cần di chuyển
          foundIndex = index; // Lưu vị trí của mục cần di chuyển
          break; // Thoát khỏi vòng lặp sau khi tìm thấy phần tử
        }
      }
      if (foundItem !== null) {
        const newChatList = [...listChat];
        newChatList.splice(foundIndex, 1); // Xóa mục cũ khỏi danh sách
        foundItem.status = "chua-xem";
        foundItem.created_at = result?.created_at;
        foundItem.sender = user;
        foundItem.receiver = userChat;
        newChatList.unshift(foundItem); // Chèn mục mới vào đầu danh sách
        setListChat(newChatList);
      } else {
        result.sender = user;
        result.receiver = userChat;
        const newItem = result;
        const newChatList = [newItem, ...listChat];
        setListChat(newChatList);
      }
      const action = viewedMessage(userId);
      const resultAction = await dispatch(action); // Sửa lỗi chính tả
      const res = unwrapResult(resultAction);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  // useEffect(() => {
  //   const fetchMessages = async () => {
  //     try {
  //       const dataRes = await chatApi.getMessages(userId);
  //       setMessages(dataRes.messages);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   if (isLoggedIn) {
  //     const pusher = new Pusher("edbe3c1ada201abc1182", {
  //       cluster: "ap1",
  //     });
  //     const channel = pusher.subscribe(`new-message.${user?.id}`);

  //     channel.bind("App\\Events\\NewMessage", function (data) {
  //       const userChat =
  //         user?.id === data?.message?.sender_id
  //           ? data?.message?.receiver_id
  //           : data?.message?.sender_id;

  //       if (listChat) {
  //         listChat.forEach((item) => {
  //           if (item.sender_id === userChat) {
  //             fetchMessages();
  //             // const SetStatusMessage = props.SetStatusMessage;
  //             // SetStatusMessage(item);
  //           }
  //         });
  //       }
  //     });
  //     fetchMessages();
  //     return () => {
  //       channel.unbind();
  //       pusher.unsubscribe(`new-message.${user?.id}`);
  //     };
  //   }
  // }, [userId]);
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const dataRes = await chatApi.getMessages(userId);
        setMessages(dataRes.messages);
      } catch (error) {
        console.log(error);
      }
    };
    if (isLoggedIn) {
      const pusher = new Pusher("feaaa31f3ed0060080b5", {
        cluster: "ap1",
      });
      const channel = pusher.subscribe(`new-message.${user?.id}`);
      channel.bind("App\\Events\\NewMessage", function (data) {
        fetchMessages();
      });
      fetchMessages();
      return () => {
        channel.unbind();
        pusher.unsubscribe(`new-message.${user?.id}`);
      };
    }
  }, [userId]);

  if (userId === "new") {
    return (
      <Box className="empty-chat">
        <CommentsDisabledIcon
          style={{ marginRight: "10px", marginTop: "7px" }}
        ></CommentsDisabledIcon>
        Chưa có đoạn chat nào được chọn
      </Box>
    );
  }
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        style={{
          backgroundColor: "#ecf3ff",
          padding: "10px",
        }}
        className="banner-chat"
      >
        <Grid item marginRight={2}>
          <Avatar
            alt={`${userChat?.full_name}`}
            src={`${baseURL}${userChat?.image}`}
            width={50}
            height={50}
          />
        </Grid>
        <Grid item>
          <p className="m-0 fw-bold">{userChat?.full_name}</p>
        </Grid>
      </Grid>
      <div className="list-messages">
        {messages.map((message) => (
          <div
            className={`message-wrapper ${
              message?.sender_id == user?.id ? "sent" : "received"
            }`}
            key={message?.id}
          >
            {message?.sender_id != user?.id && (
              <Avatar
                alt="user"
                src={`${baseURL}${message?.images}`}
                width={50}
                height={50}
                className="avatar"
              />
            )}
            <div className="message-content">
              <div className="message-info">
                <Tooltip
                  title={dayjs(message?.created_at).format("HH:mm DD/MM/YYYY")}
                >
                  <p className="message-text">{message?.content}</p>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit(newMessage)}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", marginRight: "10px" }}>
            <TextField
              error={Boolean(errors.message)}
              id="message"
              style={{ width: "100%", marginLeft: "10px" }}
              size="small"
              label="Nhập tin nhắn"
              defaultValue=""
              helperText={errors.message?.message}
              {...message("message")}
            />
            <TextField
              id="userId"
              value={userIdNumber}
              hidden
              {...message("userId")}
            />
          </div>
          <div>
            <Button
              variant="outlined"
              size="small"
              color="warning"
              type="submit"
              className="mb-2"
              disabled={!isValid}
              style={{ maxWidth: "40px", border: "none" }}
            >
              <Tooltip title="Nhấn để gửi tin nhắn">
                <SendIcon fontSize="large" />
              </Tooltip>
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
}

export default Chat;
