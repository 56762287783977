import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import chatApi from "../Api/chatApi";
export const receivedNewMessage = createAction('message/receivedNewMessage');
export const viewedMessage = createAsyncThunk(
    '/message-viewed',
    async (payload) => {
        try {
            const response = await chatApi.setStatusMessages(payload);
            return response;
        } catch (error) {
            throw error;
        }
    }
);
export const MessageSlice = createSlice({
    name: 'message',
    initialState: {
        messageItems: 0,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(viewedMessage.fulfilled, (state, action) => {
                state.messageItems += 1;
            })
            .addCase(receivedNewMessage, (state) => {
                state.messageItems += 1;
            });
    },
});

const { actions, reducer } = MessageSlice;
export default reducer;
