import axios from "axios";
import axiosClient from "./axiosClient";

const locationApi = {

    getFoodLocations(params) {
        const url = '/api/get-locations';
        return axiosClient.get(url, { params });
    },

    getDetailLocation(locationSlug) {
        const url = `/api/get-detail-location/${locationSlug}`;
        return axiosClient.get(url);
    },
    getListLocationOfUser(params) {
        const url = '/api/get-list-location-of-user';
        return axiosClient.get(url, { params });
    },

    addNewDonateLocation(data) {
        const url = `/api/new-location-donate`;
        const headers = {
            "Content-Type": "multipart/form-data"
        };
        return axiosClient.post(url, data, { headers });
    },
    setLockLocation(data) {
        const url = '/api/set-lock-location';
        return axiosClient.post(url, data);
    },
    setOpenLocation(data) {
        const url = '/api/set-open-location';
        return axiosClient.post(url, data);
    },
    editLocation(data) {
        const url = '/api/edit-location';
        const headers = {
            "Content-Type": "multipart/form-data"
        };
        return axiosClient.post(url, data, { headers });
    },


}
export default locationApi;