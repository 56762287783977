import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../style/AddNewDonateLocation.css";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import addressApi from "../../../Api/addressApi";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AddNewAddress from "../../Profice/Address/AddNewAddress";
import { useTheme } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "styled-components";
import locationApi from "../../../Api/food_locations";
import { enqueueSnackbar } from "notistack";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ThumbnailImageDetail = styled("img")(({ theme, isSelected }) => ({
  borderRadius: "0.5em",
  objectFit: "cover",
  padding: "2px",
  width: "137px",
  height: "137px",
}));
AddNewDonateLocation.propTypes = {};

function AddNewDonateLocation(props) {
  const [address, setAddress] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [newAddressDialogOpen, setNewAddressDialogOpen] = useState(false);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const fetchData = useCallback(async () => {
    try {
      const response = await addressApi.getAllAddress();
      setAddressList(response);
    } catch (error) {
      console.log("Failed to fetch address list", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (addressList && addressList.length > 0) {
      const defaultAddress = addressList.find((address) => address.note == 1);
      if (defaultAddress) {
        setAddress(defaultAddress.id);
      }
    }
  }, [addressList]);

  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };

  const handleOpenNewAddressDialog = () => {
    setNewAddressDialogOpen(true);
  };

  const handleCloseNewAddressDialog = () => {
    setNewAddressDialogOpen(false);
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );
    const imagesArray = imageFiles.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages(imagesArray);
  };

  const schema = yup.object({
    name: yup
      .string()
      .required("Vui lòng nhập tên địa điểm phát thực phẩm")
      .max(255, "Vui lòng nhập ít hơn 255 kí tự")
      .min(10, "Vui lòng nhập dài hơn 10 kí tự"),
    contact_person: yup
      .string()
      .required("Vui lòng nhập tên người đại diện")
      .max(50, "Vui lòng nhập ít hơn 50 kí tự")
      .min(3, "Vui lòng nhập dài hơn 3 kí tự"),
    time: yup
      .string()
      .required(
        "Vui lòng nhập thời gian phát thực phẩm (ví dụ: phát từ 11h-13h T2,T3)"
      )
      .max(100, "Vui lòng nhập ít hơn 100 kí tự")
      .min(3, "Vui lòng nhập dài hơn 3 kí tự"),
    description: yup
      .string()
      .required(
        "Vui lòng nhập mô tả (ví dụ: địa điểm phát những thực phẩm gì, bao nhiêu suất)"
      )
      .max(1000, "Vui lòng nhập ít hơn 1000 kí tự")
      .min(10, "Vui lòng nhập mô tả dài hơn"),
    address_id: yup
      .number("Vui lòng chọn Địa chỉ")
      .typeError("Vui lòng chọn Địa chỉ")
      .required("Vui lòng chọn Địa chỉ"),
    image: yup
      .mixed()
      .test("fileRequired", "Vui lòng tải lên một hình ảnh.", (value) => {
        return value && value.length > 0; // Kiểm tra xem có hình ảnh được tải lên không
      })
      .test(
        "fileSize",
        "Kích thước hình ảnh quá lớn, vui lòng tải lên hình ảnh nhỏ hơn 2MB.",
        (value) => {
          if (!value || value.length === 0) return true; // Trường hợp không có hình ảnh, không cần validate
          return value[0].size <= 2 * 1024 * 1024; // 1MB
        }
      )
      .test(
        "fileType",
        "Tệp đã tải lên không phải là một tệp hình ảnh.",
        (value) => {
          if (!value || value.length === 0) return true; // Trường hợp không có hình ảnh, không cần validate
          const acceptedImageTypes = [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
          ]; // Các loại hình ảnh chấp nhận được
          return acceptedImageTypes.includes(value[0].type);
        }
      ),
  });

  const {
    register: registerDonateLocation,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("contact_person", data.contact_person);
      formData.append("time", data.time);
      formData.append("description", data.description);
      formData.append("address_id", data.address_id);
      formData.append("image", data.image[0]);
      const result = await locationApi.addNewDonateLocation(formData);
      console.log(result);
      if (result.message) {
        enqueueSnackbar(result.message, { variant: "success" });
        setSelectedImages(null);
        reset();
      }
    } catch (error) {
      console.log("Error Register Donate Location:", error);
    }
  };

  return (
    <div className="container-custom">
      <form
        className="col-12 col-sm-12 col-md-10 col-ld-10 col-xl-6"
        style={{ margin: "0 auto" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Paper elevation={2} className="paper">
          <h1 className="title">Đăng Kí Địa Điểm Phát Thực Phẩm</h1>
          <p className="text-danger">
            Lưu ý: Các thông tin về địa điểm phát thực phẩm đăng tải lên website
            FoodShare phải đúng sự thật và phải chịu trách nhiệm trước pháp
            luật!
          </p>
          <div className="box-form">
            <TextField
              className="col-md-12 col-lg-6 col-12 field"
              color="warning"
              id="name"
              label="Tên địa điểm phát thực phẩm"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              {...registerDonateLocation("name")}
            />
            <TextField
              className="col-md-12 col-lg-6 col-12 field field-pd"
              color="warning"
              id="contact_person"
              label="Tên người đại diện"
              error={Boolean(errors.contact_person)}
              helperText={errors.contact_person?.message}
              {...registerDonateLocation("contact_person")}
            />
            <TextField
              className="col-md-12 col-lg-12 col-12 field"
              color="warning"
              id="time"
              label="Thời gian phát thực phẩm"
              error={Boolean(errors.time)}
              helperText={errors.time?.message}
              {...registerDonateLocation("time")}
            />
            {address ? (
              <>
                <div className="text-end">
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: "5px", width: "149px" }}
                    onClick={handleOpenNewAddressDialog}
                  >
                    Thêm mới địa chỉ
                  </Button>
                </div>
                <FormControl className="col-lg-12 col-md-12 col-12 field">
                  <InputLabel id="address">Chọn địa chỉ</InputLabel>
                  <Select
                    id="address_id"
                    value={address}
                    label="Chọn địa chỉ"
                    error={Boolean(errors.address_id)}
                    {...registerDonateLocation("address_id")}
                    onChange={handleChangeAddress}
                  >
                    {addressList?.map((address) => (
                      <MenuItem key={address.id} value={address?.id}>
                        {address.contact_information}
                        {", "}
                        {address.formatted_address}
                      </MenuItem>
                    ))}
                  </Select>

                  {errors.address_id?.message ? (
                    <p className="text-danger">{errors.address_id?.message}</p>
                  ) : (
                    ""
                  )}
                </FormControl>
              </>
            ) : (
              <div className="text-end">
                <Button
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    width: "149px",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={handleOpenNewAddressDialog}
                >
                  Thêm mới địa chỉ
                </Button>{" "}
              </div>
            )}
            <TextField
              className="col-md-12 col-lg-12 col-12 field"
              color="warning"
              multiline
              minRows={4}
              maxRows={6}
              id="description"
              label="Mô tả điểm phát thực phẩm"
              error={Boolean(errors.description)}
              helperText={errors.description?.message}
              {...registerDonateLocation("description")}
            />
          </div>
          <div className="text-end">
            <Button
              component="label"
              variant="outlined"
              size="small"
              startIcon={<CloudUploadIcon />}
            >
              Thêm Hình Ảnh
              <VisuallyHiddenInput
                type="file"
                id="image"
                multiple
                {...registerDonateLocation("image")}
                onChange={handleFileChange}
              />
            </Button>
            {errors.image?.message ? (
              <p className="text-danger" style={{ fontSize: "0.8em" }}>
                {errors.image?.message}
              </p>
            ) : (
              ""
            )}
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginTop: "8px",
              }}
              className="col-lg-12 col-md-12 col-12"
            >
              {selectedImages?.map((image, index) => (
                <ThumbnailImageDetail
                  key={index}
                  src={image}
                  alt={`Selected ${index}`}
                />
              ))}
            </div>
          </div>
          <div className="text-center">
            <Button
              variant="contained"
              color="warning"
              type="submit"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                minWidth: "150px",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Đăng Kí"
              )}
            </Button>
          </div>
        </Paper>
      </form>
      <Dialog
        open={newAddressDialogOpen}
        onClose={handleCloseNewAddressDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle id="alert-dialog-title">{"Thêm mới địa chỉ"}</DialogTitle>
        <DialogContent>
          <AddNewAddress fetchData={fetchData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewAddressDialog}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewDonateLocation;
