import { Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Avatar, Button, Popover, Link } from "@mui/material";
import { baseURL } from "../../../Constants/env";

LocationInfomation.propTypes = {
  location: PropTypes.object,
};

function LocationInfomation(location) {
  const icon = L.icon({
    iconUrl: "/placeholder.png",
    iconSize: [50, 50],
  });

  const [popover, setPopover] = React.useState(null);
  const openPopover = Boolean(popover);
  const id = openPopover ? "simple-popover" : undefined;
  const handleClosePopover = () => {
    setPopover(null);
  };
  const handleClickPopover = async (event) => {
    setPopover(event.currentTarget);
  };
  return (
    <Box style={{ marginTop: "24px" }}>
      <Paper elevation={0}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          style={{ padding: "10px", paddingTop: "15px" }}
        >
          <Grid item marginRight={2}>
            <Avatar
              sx={{ width: 50, height: 50 }}
              alt="Avatar"
              src={`${baseURL}${location?.location?.user?.image}`}
            />
          </Grid>
          <Grid>
            <Button
              className="fw-bold p-0"
              style={{ textTransform: "none", fontSize: "1.5em" }}
              aria-describedby={id}
              onClick={handleClickPopover}
            >
              {location?.location?.user?.full_name}
            </Button>
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popover}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Link
                href={`https://foodshare.id.vn/messages/${location?.location?.user?.id}`}
                underline="none"
                style={{margin:"20px"}}
              >
                Nhắn tin
              </Link>
            </Popover>
          </Grid>
        </Grid>
        <Grid padding={1}>
          <Typography variant="h5" className="fw-bold">
            Tên Địa Điểm: {location?.location?.name}
          </Typography>
        </Grid>
        <Grid padding={1}>
          <Typography color="warning" className="fw-bolder">
            Tên người Đại Diện: {location?.location?.contact_person}
          </Typography>
        </Grid>
        <Grid padding={1}>
          <Typography>
            Thông Tin Liên Hệ: {location?.location?.contact_information}
          </Typography>
        </Grid>
        <Grid padding={1}>
          <Typography className="text-warning">
            Thời Gian Phát: {location?.location?.time}
          </Typography>
        </Grid>
        <Grid padding={1}>
          <Typography>Mô tả: {location?.location?.description}</Typography>
        </Grid>
        <Grid padding={1}>
          <Typography className="text-success">
            <AddLocationIcon />
            {location?.location?.formatted_address}
          </Typography>
        </Grid>
        <Grid padding={1}>
          <MapContainer
            center={
              location?.location?.lat !== undefined &&
              location?.location?.lon !== undefined
                ? [location?.location?.lat, location?.location?.lon]
                : [16.0893519, 108.237497]
            }
            zoom={10}
            style={{ height: "300px" }}
            className="col-12 col-md-12 col-lg-12"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={
                location?.location?.lat !== undefined &&
                location?.location?.lon !== undefined
                  ? [location?.location?.lat, location?.location?.lon]
                  : [16.0893519, 108.237497]
              }
              icon={icon}
            >
              <Popup>
                Vị trí của bạn: <br /> Latitude: {location?.location?.lat},
                Longitude: {location?.location?.lon}
              </Popup>
            </Marker>
          </MapContainer>
        </Grid>
      </Paper>
    </Box>
  );
}

export default LocationInfomation;
