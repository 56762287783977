import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Typography,
} from "@mui/material";
import food_locations from "../../../Api/food_locations";
import "../../../style/AddNewDonateLocation.css";
import addressApi from "../../../Api/addressApi";
import AddNewAddress from "../../Profice/Address/AddNewAddress";
import { useTheme } from "@mui/material/styles";
import styled from "styled-components";
import { baseURL } from "../../../Constants/env";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import locationApi from "../../../Api/food_locations";
import { enqueueSnackbar } from "notistack";
const ThumbnailImageDetail = styled("img")(({ theme, isSelected }) => ({
  borderRadius: "0.5em",
  objectFit: "cover",
  padding: "2px",
  width: "137px",
  height: "137px",
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function EditLocation() {
  const { locationSlug } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [address_id, setAddress_id] = useState(null);
  const [newAddressDialogOpen, setNewAddressDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        setLoading(true);
        const result = await food_locations.getDetailLocation(locationSlug);
        setLocation(result);
      } catch (error) {
        console.log("Failed to fetch Location", error);
      } finally {
        setLoading(false);
      }
    };
    fetchLocation();
  }, [locationSlug]);

  const fetchData = useCallback(async () => {
    try {
      const response = await addressApi.getAllAddress();
      setAddressList(response);
    } catch (error) {
      console.log("Failed to fetch address list", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (location) {
      if (location.address_id) {
        setAddress_id(location.address_id);
      }
      setValue("name", location.name);
      setValue("contact_person", location.contact_person);
      setValue("time", location.time);
      setValue("description", location.description);
    }
  }, [location]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );
    const imagesArray = imageFiles.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages(imagesArray);
  };

  const schema = yup.object({
    name: yup
      .string()
      .required("Vui lòng nhập tên địa điểm phát thực phẩm")
      .max(255, "Vui lòng nhập ít hơn 255 kí tự")
      .min(10, "Vui lòng nhập dài hơn 10 kí tự"),
    contact_person: yup
      .string()
      .required("Vui lòng nhập tên người đại diện")
      .max(50, "Vui lòng nhập ít hơn 50 kí tự")
      .min(3, "Vui lòng nhập dài hơn 3 kí tự"),
    time: yup
      .string()
      .required(
        "Vui lòng nhập thời gian phát thực phẩm (ví dụ: phát từ 11h-13h T2,T3)"
      )
      .max(100, "Vui lòng nhập ít hơn 100 kí tự")
      .min(3, "Vui lòng nhập dài hơn 3 kí tự"),
    description: yup
      .string()
      .required(
        "Vui lòng nhập mô tả (ví dụ: địa điểm phát những thực phẩm gì, bao nhiêu suất)"
      )
      .max(1000, "Vui lòng nhập ít hơn 1000 kí tự")
      .min(10, "Vui lòng nhập mô tả dài hơn"),
  });

  const {
    register: editLocation,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    console.log(data);
    formData.append("name", data.name);
    formData.append("id", location.id);
    formData.append("contact_person", data.contact_person);
    formData.append("time", data.time);
    formData.append("description", data.description);
    formData.append(
      "address_id",
      data.address_id !== "" ? data.address_id : location.address_id
    );
    console.log(data);
    formData.append("image", data.image[0]);
    const result = await locationApi.editLocation(formData);
    if (result.message) {
      enqueueSnackbar(result.message, { variant: "success" });
    }
  };

  if (loading) {
    return (
      <div className="container-custom">
        <Paper
          elevation={2}
          style={{
            height: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="paper col-12 col-sm-12 col-md-10 col-ld-10 col-xl-6"
        >
          <CircularProgress size={24} color="inherit" />
        </Paper>
      </div>
    );
  }
  const handleOpenNewAddressDialog = () => {
    setNewAddressDialogOpen(true);
  };

  const handleCloseNewAddressDialog = () => {
    setNewAddressDialogOpen(false);
  };
  return (
    <div className="container-custom">
      <form
        className="col-12 col-sm-12 col-md-10 col-ld-10 col-xl-6"
        style={{ margin: "0 auto" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Paper elevation={2} className="paper">
          <h1 className="title">Sửa Địa Điểm Phát Thực Phẩm</h1>
          <p className="text-danger">
            Lưu ý: Các thông tin về địa điểm phát thực phẩm đăng tải lên website
            FoodShare phải đúng sự thật và phải chịu trách nhiệm trước pháp
            luật!
          </p>
          <TextField
            className="col-md-12 col-lg-6 col-12 field"
            color="warning"
            label="Tên địa điểm"
            defaultValue={location?.name || ""}
            {...editLocation("name")}
          />
          <TextField
            label="Người đại diện"
            className="col-md-12 col-lg-6 col-12 field field-pd"
            color="warning"
            defaultValue={location?.contact_person || ""}
            {...editLocation("contact_person")}
          />
          <TextField
            label="Thời gian phát"
            className="col-md-12 col-lg-12 col-12 field"
            color="warning"
            defaultValue={location?.time || ""}
            {...editLocation("time")}
          />
          <div className="text-end">
            <Button
              variant="outlined"
              size="small"
              style={{ marginBottom: "5px", width: "149px" }}
              onClick={handleOpenNewAddressDialog}
            >
              Thêm mới địa chỉ
            </Button>
          </div>
          <FormControl className="col-lg-12 col-md-12 col-12 field">
            <InputLabel id="address">Chọn địa chỉ</InputLabel>
            <Select
              id="address_id"
              label="Chọn địa chỉ"
              value={address_id || location?.address_id || ""}
              {...editLocation("address_id")}
              onChange={(e) => setAddress_id(e.target.value)}
            >
              {addressList?.map((address) => (
                <MenuItem key={address.id} value={address.id}>
                  {address.contact_information}, {address.formatted_address}
                </MenuItem>
              ))}
            </Select>

            {errors.address_id?.message ? (
              <p className="text-danger">{errors.address_id?.message}</p>
            ) : (
              ""
            )}
          </FormControl>
          <TextField
            multiline
            minRows={4}
            maxRows={6}
            label="Mô tả"
            className="col-md-12 col-lg-12 col-12 field"
            color="warning"
            defaultValue={location?.description || ""}
            {...editLocation("description")}
          />
          {/* <div className="text-end">
            <Button
              component="label"
              variant="outlined"
              size="small"
              startIcon={<CloudUploadIcon />}
            >
              Thêm Hình Ảnh
              <VisuallyHiddenInput
                type="file"
                id="image"
                multiple
                {...editLocation("image")}
                onChange={handleFileChange}
              />
            </Button>
            {errors.image?.message ? (
              <p className="text-danger" style={{ fontSize: "0.8em" }}>
                {errors.image?.message}
              </p>
            ) : (
              ""
            )}
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginTop: "8px",
              }}
              className="col-lg-12 col-md-12 col-12"
            >
              {selectedImages?.map((image, index) => (
                <ThumbnailImageDetail
                  key={index}
                  src={image}
                  alt={`Selected ${index}`}
                />
              ))}
            </div>
          </div> */}
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: "8px",
              width: "100%",
            }}
          >
            <Button
              component="label"
              color="warning"
              size="small"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Sửa Hình Ảnh
              <VisuallyHiddenInput
                type="file"
                multiple
                id="images"
                {...editLocation("image")}
                onChange={handleFileChange}
              />
            </Button>
            {errors.image?.message ? (
              <p className="text-danger">{errors.image?.message}</p>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: "8px",
              width: "100%",
            }}
          >
            <ThumbnailImageDetail
              key={location?.image}
              src={`${baseURL}${location?.image}`}
              alt={`Selected ${location?.image}`}
            />
          </div>

          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: "8px",
              width: "100%",
            }}
          >
            {selectedImages.length > 0 ? (
              <Typography
                variant="h6"
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  color: "#ED6C02",
                }}
              >
                Ảnh Mới
              </Typography>
            ) : (
              ""
            )}
            {selectedImages.map((image, index) => (
              <ThumbnailImageDetail
                key={index}
                src={image}
                alt={`Selected ${index}`}
              />
            ))}
          </div>

          <div className="text-center">
            <Button
              variant="contained"
              color="warning"
              type="submit"
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                minWidth: "150px",
              }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Xác Nhận"
              )}
            </Button>
          </div>
        </Paper>
      </form>
      <Dialog
        open={newAddressDialogOpen}
        onClose={handleCloseNewAddressDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle id="alert-dialog-title">{"Thêm mới địa chỉ"}</DialogTitle>
        <DialogContent>
          <AddNewAddress fetchData={fetchData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewAddressDialog}>Đóng</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditLocation;
