import FastfoodIcon from "@mui/icons-material/Fastfood";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import "../../style/Footer.css";
import React, { useState } from "react";
import Paypal from "./Paypal/Paypal";
import { PayPalButton } from "react-paypal-button-v2";
import donateApi from "../../Api/donateApi";
import { enqueueSnackbar } from "notistack";
Footer.propTypes = {};

function Footer(props) {
  const [amount, setAmount] = useState(10.0); // State để lưu giá trị nhập vào ô input
  const [open, setOpen] = useState(false); // State để kiểm soát hiển thị dialog
  const [content, setContent] = useState(""); // State để kiểm soát hiển thị dialog

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount || "10.00", // Sử dụng giá trị của amount hoặc mặc định là "0.00" nếu không có giá trị nhập vào
          },
          payee: {
            email_address: "sb-o9pma26161202@business.example.com",
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    try {
      const details = await actions.order.capture();
      details.content = content;
      const result = await donateApi.donate(details);
      if (result.message) {
        enqueueSnackbar(result.message, { variant: "success" });
      }
      setContent("");
      handleClose(); // Đóng dialog sau khi thanh toán thành công
    } catch (error) {
      console.error("Error in onApprove:", error);
    }
  };

  const handleAmountChange = (event) => {
    const inputAmount = parseFloat(event.target.value);
    if (inputAmount < 1) {
      alert("Số tiền phải lớn hơn 1$");
      setAmount(amount);
      return;
    }
    setAmount(inputAmount);
  };

  const handleContentChange = (event) => {
    const newContent = event.target.value;
    console.log(newContent.length); // Log độ dài của nội dung mới
    if (newContent.length > 255) {
      alert("Vui lòng nhập nội dung ngắn hơn");
      return;
    }
    setContent(newContent); // Cập nhật state content của component
  };

  const handleClickOpen = () => {
    setOpen(true); // Mở dialog khi người dùng click vào nút donate
  };

  const handleClose = () => {
    setOpen(false); // Đóng dialog
  };
  return (
    <Box
      paddingTop={3}
      className="d-flex justify-content-center"
      style={{ backgroundColor: "#ED6C02" }}
    >
      <footer className="col-11 col-md-11 col-lg-9">
        <div class="row">
          <div class="col-md-4">
            <div style={{ display: "flex" }}>
              <h3
                noWrap
                component="a"
                href="/foods/tat-ca-thuc-pham"
                className="text-white h3"
                sx={{
                  mr: 2,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".35rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                FoodShare
              </h3>
            </div>
            <p className="text-white">
              Tặng những gì bạn có thể, nhận những gì bạn cần. Hãy cùng
              FoodShare để chia sẻ những món ăn, những niềm vui mỗi ngày.
              FoodShare luôn đồng hành cùng bạn.
            </p>
          </div>
          <div class="col-md-4">
            <h3 className="text-white h3">Thông tin liên hệ</h3>
            <p className="text-white">Phone: +84 911.617.107</p>
            <p className="text-white">Email: foodshare@gmail.com</p>
          </div>
          <div class="col-md-4">
            <h3 className="text-white h3">Quyên góp Quỹ</h3>
            <p className="text-white">
              Số tiền quyên góp sẽ được sử dụng cho các buổi phát đồ ăn, mua áo
              quần, sách vở cho trẻ em vùng khó khăn....
            </p>
            <Button
              variant="contained"
              className="button mt-2"
              color="error"
              onClick={handleClickOpen}
            >
              Quyên Góp
            </Button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
              <DialogTitle>Quyên góp vào quỹ FoodShare</DialogTitle>
              <DialogContent>
                <p>
                  Số tiền quyên góp sẽ được sử dụng cho các buổi phát đồ ăn, mua
                  áo quần, sách vở cho trẻ em vùng khó khăn....
                </p>
                <TextField
                  label="Số tiền donate ($)"
                  type="number"
                  value={amount}
                  onChange={handleAmountChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                />
                <TextField
                  label="Nội dung quyên góp"
                  type="text"
                  value={content}
                  onChange={handleContentChange}
                  variant="outlined"
                  margin="normal"
                  multiline
                  minRows={3}
                  maxRows={5}
                  fullWidth
                />
                <PayPalButton
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <hr class="border border-800" />
        <div class="row flex-center pb-3">
          <div class="col-md-6 order-0">
            <p class="text-200 text-center text-md-start text-white">
              All rights Reserved © FoodShare, 2023
            </p>
          </div>
          <div class="col-md-6 order-1">
            <p class="text-200 text-center text-md-end text-white">
              {" "}
              Made with&nbsp;
              <svg
                class="bi bi-suit-heart-fill"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="#FFB30E"
                viewBox="0 0 16 16"
              >
                <path d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z"></path>
              </svg>
              &nbsp;by&nbsp;
              <a
                class="text-200 fw-bold"
                href="https://foodshare.id.vn/"
                target="_blank"
                className="text-white"
                rel="noreferrer"
              >
                Foodshare{" "}
              </a>
            </p>
          </div>
        </div>
      </footer>
    </Box>
  );
}

export default Footer;
