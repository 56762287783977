import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import chatApi from "../../Api/chatApi";
import { Avatar, Box, Grid, Skeleton, Typography } from "@mui/material";
import { baseURL } from "../../Constants/env";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Chat from "./Chat";
import "../../style/MainChat.css";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import dayjs from "dayjs";
import { viewedMessage } from "../../Slide/MessageSlide";
import { receivedNewMessage } from "../../Slide/MessageSlide";
import { unwrapResult } from "@reduxjs/toolkit";
import userApi from "../../Api/userApi";
MainChat.propTypes = {};

function MainChat(props) {
  const loggedInuser = useSelector((state) => state?.user?.current);
  const isLoggedIn = !!loggedInuser?.id;
  const [loading, setLoading] = useState(false);
  const [listChat, setListChat] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await chatApi.getAllUsersChat();
      setListChat(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isLoggedIn) {
      const pusher = new Pusher("feaaa31f3ed0060080b5", {
        cluster: "ap1",
      });
      const channel = pusher.subscribe(`new-message.${user?.id}`);
      channel.bind("App\\Events\\NewMessage", async function (data) {
        console.log("1");
        const newMessage = data?.message;
        const userReceiverId = user?.id;
        const userSenderId = data?.message?.sender_id;
        const UserChat = newMessage.sender;
        const message = data?.message?.content;
        setListChat((prevListChat) => {
          const existingMessageIndex = prevListChat.findIndex(
            (item) =>
              (item.sender_id == userSenderId &&
                item.receiver_id == userReceiverId) ||
              (item.sender_id == userReceiverId &&
                item.receiver_id == userSenderId)
          );

          if (existingMessageIndex != -1) {
            console.log("2");
            let updatedChat = { ...prevListChat[existingMessageIndex] };
            dispatch(receivedNewMessage());
            updatedChat.content = message;
            updatedChat.status = "chua-xem";
            updatedChat.created_at = data?.message?.created_at;
            updatedChat.sender = UserChat;
            updatedChat.receiver = user;
            const newChatList = [...prevListChat];
            newChatList[existingMessageIndex] = updatedChat;
            console.log(newChatList);
            return sortChatListByTime(newChatList);
          } else {
            console.log("3");
            let newChat = {
              ...newMessage,
              sender: UserChat,
              receiver: user,
              status: "chua-xem",
              created_at: data?.message?.created_at,
            };

            const newChatList = [newChat, ...prevListChat];
            console.log(newChatList);
            return sortChatListByTime(newChatList);
          }
        });
      });
      return () => {
        channel.unbind();
        pusher.unsubscribe(`new-message.${user?.id}`);
      };
    }
  }, [isLoggedIn, user]);

  const sortChatListByTime = (chatList) => {
    return chatList.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  };
  const SetStatusMessage = async (chat) => {
    try {
      if (chat.status == "chua-xem") {
        // Sửa điều kiện so sánh
        const userChat =
          user?.id == chat?.sender?.id ? chat?.receiver?.id : chat?.sender?.id;
        const action = viewedMessage(userChat);
        const resultAction = await dispatch(action); // Sửa lỗi chính tả
        const result = unwrapResult(resultAction);
        setListChat((prevListChat) =>
          prevListChat.map((item) =>
            item.id == chat.id ? { ...item, status: "da-xem" } : item
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row row-main">
      <div
        className="col-md-4 col-lg-3 col-12 row-list-chat"
        style={{ borderRight: "1px solid #DCDCDC" }}
      >
        <h1 className="list-chat-title">Tin Nhắn</h1>
        {loading ? (
          <ul className="ul-loading">
            <li style={{ marginBottom: "10px" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <>
                  <Grid item marginRight={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                  </Grid>
                  <Grid item>
                    <Box className="text-width-loading">
                      <Skeleton />
                      <Skeleton />
                      <Skeleton style={{ width: "30%" }} />
                    </Box>
                  </Grid>
                </>
              </Grid>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <>
                  <Grid item marginRight={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                  </Grid>
                  <Grid item>
                    <Box className="text-width-loading">
                      <Skeleton />
                      <Skeleton />
                      <Skeleton style={{ width: "30%" }} />
                    </Box>
                  </Grid>
                </>
              </Grid>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <>
                  <Grid item marginRight={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                  </Grid>
                  <Grid item>
                    <Box className="text-width-loading">
                      <Skeleton />
                      <Skeleton />
                      <Skeleton style={{ width: "30%" }} />
                    </Box>
                  </Grid>
                </>
              </Grid>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <>
                  <Grid item marginRight={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                  </Grid>
                  <Grid item>
                    <Box className="text-width-loading">
                      <Skeleton />
                      <Skeleton />
                      <Skeleton style={{ width: "30%" }} />
                    </Box>
                  </Grid>
                </>
              </Grid>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <>
                  <Grid item marginRight={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                  </Grid>
                  <Grid item>
                    <Box className="text-width-loading">
                      <Skeleton />
                      <Skeleton />
                      <Skeleton style={{ width: "30%" }} />
                    </Box>
                  </Grid>
                </>
              </Grid>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <>
                  <Grid item marginRight={2}>
                    <Skeleton variant="circular" width={50} height={50} />
                  </Grid>
                  <Grid item>
                    <Box className="text-width-loading">
                      <Skeleton />
                      <Skeleton />
                      <Skeleton style={{ width: "30%" }} />
                    </Box>
                  </Grid>
                </>
              </Grid>
            </li>
          </ul>
        ) : (
          <>
            <ul className="ul-loaded">
              {listChat &&
                listChat.map((chat, index) => (
                  <li
                    key={index}
                    className="link-chat"
                    style={{
                      backgroundColor: location.pathname.includes(
                        `/messages/${
                          user?.id == chat?.sender?.id
                            ? chat?.receiver?.id
                            : chat?.sender?.id
                        }`
                      )
                        ? "#ecf3ff"
                        : "",
                    }}
                  >
                    <Link
                      to={`/messages/${
                        user?.id == chat?.sender?.id
                          ? chat?.receiver?.id
                          : chat?.sender?.id
                      }`}
                      onClick={() => SetStatusMessage(chat)}
                      style={{ textDecoration: "none" }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {user?.id == chat?.sender?.id ? (
                          <>
                            <Grid item marginRight={2}>
                              <Avatar
                                alt={`${chat?.receiver?.full_name}`}
                                src={`${baseURL}${chat?.receiver?.image}`}
                                width={50}
                                height={50}
                              />
                            </Grid>

                            <Grid item>
                              <p className="mb-0 text-black name-user-chat">
                                {chat?.receiver?.full_name}
                              </p>
                              <div>
                                <p className="mb-0 text-black">
                                  Bạn:{" "}
                                  {chat?.content && chat.content.length > 16
                                    ? `${chat.content.substring(0, 16)}...`
                                    : chat?.content}
                                </p>
                                <p className="mb-0">
                                  {dayjs(chat?.created_at).format("HH:mm")}
                                </p>
                              </div>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item marginRight={2}>
                              <Avatar
                                alt={`${chat?.sender?.full_name}`}
                                src={`${baseURL}${chat?.sender?.image}`}
                                width={50}
                                height={50}
                              />
                            </Grid>

                            <Grid item>
                              <p className="mb-0 text-black name-user-chat">
                                {chat?.sender?.full_name}
                              </p>
                              <p
                                style={
                                  chat?.status == "chua-xem"
                                    ? { fontWeight: "bold" }
                                    : {}
                                }
                                className="mb-0 text-black"
                              >
                                {chat?.content && chat.content.length > 15
                                  ? `${chat.content.substring(0, 15)}...`
                                  : chat?.content}
                              </p>
                              <p className="mb-0">
                                {dayjs(chat?.created_at).format("HH:mm")}
                              </p>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Link>
                  </li>
                ))}
            </ul>
          </>
        )}
      </div>
      <div className="col-md-8 col-lg-9 col-12 row-chat p-0">
        <Chat
          listChat={listChat}
          setListChat={setListChat}
          SetStatusMessage={SetStatusMessage}
        />
      </div>
    </div>
  );
}

export default MainChat;
