import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Grid, Typography } from "@mui/material";
import { baseURL } from "../../../Constants/env";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlaceIcon from "@mui/icons-material/Place";
import dayjs from "dayjs";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
const FoodContainer = styled(Box)({
  position: "relative",
  cursor: "pointer",
  border: "1px solid #efefef",
  borderRadius: "0.5em",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "1px 1px 4px #ed6c02",
    borderRadius: "0.5em",
  },
});

FoodContainer.propTypes = {
  data: PropTypes.object,
};

function FoodLocation({ data }) {
  const icon = L.icon({
    iconUrl: "/placeholder.png",
    iconSize: [50, 50],
  });
  const history = useHistory();
  const handleClick = () => {
    history.push(`/food-donation-locations/${data.slug}`);
  };

  return (
    <FoodContainer margin={1.5} onClick={handleClick}>
      <Box padding={1}>
        <img
          style={{ borderRadius: "5px", objectFit: "cover" }}
          src={`${baseURL}${data.image}`}
          alt={data.name}
          width="100%"
          height="222"
        />
      </Box>
      <Typography padding={1} variant="body3">
        {dayjs(data.created_at).format("DD/MM/YYYY HH:mm")}
      </Typography>
      <Typography
        padding={1}
        variant="body2"
        sx={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          textOverflow: "ellipsis",
          WebkitLineClamp: 2,
          minHeight: "4em",
        }}
      >
        {data.name}
      </Typography>
      <Typography padding={1} className="text-success" variant="body1">
        <PlaceIcon />
        {data.formatted_address}
      </Typography>
      {/* <Grid padding={1}>
          <MapContainer
            center={
              data?.lat !== undefined && data?.lon !== undefined
                ? [data.lat, data.lon]
                : [16.0893519, 108.237497]
            }
            zoom={10}
            style={{ height: "200px" }}
            className="col-12 col-md-12 col-lg-12"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={
                data.lat !== undefined && data.lon !== undefined
                  ? [data.lat, data.lon]
                  : [16.0893519, 108.237497]
              }
              icon={icon}
            >
              <Popup>
                Vị trí của bạn: <br /> Latitude: {data.lat}, Longitude:{" "}
                {data?.lon}
              </Popup>
            </Marker>
          </MapContainer>
        </Grid> */}
    </FoodContainer>
  );
}

export default FoodLocation;
