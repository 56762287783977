import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import locationApi from "../../../Api/food_locations";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { baseURL } from "../../../Constants/env";
import EditNoteIcon from "@mui/icons-material/EditNote";
import "../../../style/ManageLocations.css";
import TableSkeleton from "../../../Components/Skeleton/TableSkeleton";
import { enqueueSnackbar } from "notistack";
ManageFoodLocations.propTypes = {};

function ManageFoodLocations(props) {
  const location = useLocation();
  const history = useHistory();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl2);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogOpen, setOpenDialogOpen] = React.useState(false);

  const queryParams = useMemo(() => {
    const params = queryString.parse(location.search);
    return {
      ...params,
      _page: Number.parseInt(params._page) || 1,
    };
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataRes = await locationApi.getListLocationOfUser(queryParams);
        setList(dataRes.locations.data);
        setTotalPage(dataRes.locations.last_page);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [queryParams, loadData]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAnchorEl2(null);
  };

  const handleOpenDialogOpenLocation = () => {
    setOpenDialogOpen(true);
  };

  const handleCloseDialogOpenLocation = () => {
    setOpenDialog(false);
    setAnchorEl2(null);
  };

  const handleClose = () => {
    setAnchorEl2(null);
    setSelectedLocation(null);
  };

  const handlePageChange = (event, newPage) => {
    const filters = {
      ...queryParams,
      _page: newPage,
    };
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(filters),
    });
  };

  const handleClick = (event, item) => {
    try {
      setSelectedLocation(item);
      console.log(item);
      setAnchorEl2(event.currentTarget);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickEdit = () => {
    history.push(
      `manage-donated-food-locations/edit/${selectedLocation?.slug}`
    );
  };
  const handleClickView = () => {
    history.push(`/food-donation-locations/${selectedLocation?.slug}`);
  };
  const handleClickLook = () => {
    handleClickOpenDialog();
  };

  const handleLockLocation = async () => {
    const result = await locationApi.setLockLocation(selectedLocation.id);
    if (result.message) {
      enqueueSnackbar(result.message, { variant: "success" });
      setLoadData(true);
      setAnchorEl2(null);
    }
    setOpenDialog(false);
  };

  const handleOpenLocation = async () => {
    const result = await locationApi.setOpenLocation(selectedLocation.id);
    if (result.message) {
      enqueueSnackbar(result.message, { variant: "success" });
      setLoadData(true);
      setAnchorEl2(null);
    }
    setOpenDialogOpen(false);
  };

  const handleClickRegister = () => {
    window.location.href =
      "https://foodshare.id.vn/register-new-donate-location";
  };

  if (loading) {
    return (
      <Box
        paddingTop={3}
        className="col-12 col-md-12 col-lg-11"
        sx={{ marginX: "auto", textAlign: "center" }}
      >
        <TableSkeleton />
      </Box>
    );
  }

  return (
    <Box
      paddingTop={12}
      marginBottom={4}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="col-12 col-md-12 col-lg-11">
        <TableContainer component={Paper} style={{ minHeight: "700px" }}>
          <h1 className="h1 m-2 p-0" style={{ color: "#ED6C02" }}>
            Quản Lí Địa Điểm Phát Thực Phẩm
          </h1>
          <div className="text-end">
            <Button
              variant="contained"
              color="warning"
              size="small"
              style={{ marginRight: "10px" }}
              onClick={handleClickRegister}
            >
              Đăng kí điểm phát
            </Button>
          </div>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell className="text-nowrap" align="left">
                  Tên Điểm Phát
                </TableCell>
                <TableCell className="text-nowrap" align="left">
                  Tên Người Đại Diện
                </TableCell>
                <TableCell className="text-nowrap" align="left">
                  Hình Ảnh
                </TableCell>
                <TableCell
                  className="text-nowrap"
                  align="left"
                  style={{ minWidth: "200px" }}
                >
                  Mô Tả
                </TableCell>
                <TableCell className="text-nowrap" align="left">
                  Trạng Thái
                </TableCell>
                <TableCell className="text-nowrap" align="left">
                  Thời Gian Tạo
                </TableCell>
                <TableCell className="text-nowrap" align="left">
                  Thao Tác
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell align="left">{item?.name}</TableCell>
                  <TableCell align="left">{item?.contact_person}</TableCell>
                  <TableCell align="left">
                    <Avatar
                      alt={`${item?.name}`}
                      src={`${baseURL}${item?.image}`}
                    />
                  </TableCell>
                  <TableCell align="left">{item?.description}</TableCell>
                  <TableCell align="left">
                    {item.status === "chua-duyet" ? (
                      <Alert className="text-nowrap" severity="warning">
                        Chưa Duyệt
                      </Alert>
                    ) : item.status === "da-duyet" ? (
                      <Alert className="text-nowrap" severity="success">
                        Đã Duyệt
                      </Alert>
                    ) : item.status === "dang-khoa" ? (
                      <Alert severity="error">Đang Khóa</Alert>
                    ) : null}
                  </TableCell>
                  <TableCell align="left">
                    {dayjs(item.created_at).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={(e) => handleClick(e, item)}
                    >
                      <EditNoteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {totalPage > 1 && list?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Pagination
              container
              justify="center"
              color="warning"
              count={totalPage}
              page={queryParams._page}
              onChange={handlePageChange}
            />
          </div>
        ) : (
          ""
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl2}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClickView}>Xem chi tiết</MenuItem>
          {selectedLocation?.status == "da-duyet" ||
            (selectedLocation?.status == "chua-duyet" && (
              <MenuItem onClick={handleClickEdit}>Chỉnh sửa</MenuItem>
            ))}
          {selectedLocation?.status == "da-duyet" && (
            <>
              <MenuItem onClick={handleClickEdit}>Chỉnh sửa</MenuItem>
              <MenuItem onClick={handleClickLook}>Khóa điểm phát</MenuItem>
            </>
          )}
          {selectedLocation?.status == "dang-khoa" &&
            selectedLocation?.status != "admin-dang-khoa" && (
              <>
                <MenuItem onClick={handleOpenDialogOpenLocation}>
                  Mở khóa điểm phát
                </MenuItem>
              </>
            )}
        </Menu>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Xác Nhận Khóa Điểm Phát
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bạn có thực sự muốn khóa điểm phát thực phẩm này?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLockLocation}>Đồng Ý</Button>
            <Button onClick={handleCloseDialog} autoFocus>
              Hủy
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogOpen}
          onClose={handleCloseDialogOpenLocation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Xác Nhận Mở Khóa Điểm Phát
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bạn có thực sự muốn mở khóa điểm phát thực phẩm này?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenLocation}>Đồng Ý</Button>
            <Button onClick={handleCloseDialog} autoFocus>
              Hủy
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}

export default ManageFoodLocations;
